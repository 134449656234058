import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Lineup.css";

const Lineup = ({ date, artists }) => {
    const [, setwindowWidth] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setwindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleArtistClick = (id) => {
        navigate(`/artist/${id}`);
    };


    const renderArtistCard = (artist) => {
        return (
            <div key={artist.id}
                className="lineup-item"
                onClick={() => handleArtistClick(artist.id)}
                role="button" 
            >
                <div
                    className="lineup-item-bg"
                    style={{ backgroundImage: `url(${artist.imageUrl})` }}
                ></div>
                <div className="lineup-item-content">
                    <div className="lineup-artist-name">{artist.name}</div>
                    <div className="lineup-artist-details">
                        {artist.concertLocation} {artist.concertTime}
                    </div>
                </div>
            </div>
        );
    };

    if (!Array.isArray(artists) || artists.length === 0) {
        console.error("artists is not an array or it is empty");
        return null;
    }

    return (
        <div className="lineup-component"><h1 className="date">{date}</h1><div className="lineup-container">
            {artists.map((artist) => renderArtistCard(artist))}
        </div></div>
    );
};

export default Lineup;
