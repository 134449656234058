import React, { useState, useEffect } from "react";
import "./LandingPage.css";

import { IoIosArrowDown } from "react-icons/io";

const LandingPage = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const openTicketsTab = () => {
    window.open(
      "https://shotgun.live/pt-pt/events/fen-winter-sessions",
      "_blank"
    );
  };

  const scrollDownUsingArrow = () => {
    window.scrollBy({
      top: window.innerHeight,
      behavior: "smooth",
    });
  };

  const renderTextDiv = () => {
    if (screenSize.width > 767) {
      return renderDesktopTextDiv();
    } else {
      return renderMobileTextDiv();
    }
  };

  const renderFenLogo = () => {
    return (
      <div className="fen-logo-div">
        <img
          className="fen-logo-image"
          src="https://i.imgur.com/UxtGPzl.png"
          alt="fen logo"
        ></img>
      </div>
    );
  };

  const renderDesktopTextDiv = () => {
    return (
      <div>
        <div className="desktop-text-container">
          <div className="desktop-text">FEN WINTER SESSIONS</div>
          <div className="desktop-text">23 nov, 2024</div>
        </div>
      </div>
    );
  };

  const renderMobileTextDiv = () => {
    return (
      <div className="text-div">
        <div className="title-div">FEN WINTER SESSIONS</div>
        <div className="sub-title-div">FEN FESTIVAL</div>
        <div className="dates-div">23 NOV, 2024</div>
      </div>
    );
  };

  const renderTicketsButton = () => {
    return (
      <div className="tickets-button-div">
        <button className="tickets-button" onClick={openTicketsTab}>
          BILHETES
        </button>
      </div>
    );
  };

  const renderDownArrow = () => {
    return (
      <div className="down-arrow-div">
        <IoIosArrowDown
          className="down-arrow-icon"
          size={45}
          onClick={scrollDownUsingArrow}
        />
      </div>
    );
  };

  return (
    <div className="landing-page-image-container">
      {renderFenLogo()}
      {renderTextDiv()}
      {renderTicketsButton()}
      {renderDownArrow()}
    </div>
  );
};

export default LandingPage;
