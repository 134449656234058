import React, { useState, useEffect } from "react";
import { IoIosArrowUp } from "react-icons/io";
import "./ScrolltoTopButton.css";

const ScrolltoTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.scrollY > 40) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
    };
    
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
          window.removeEventListener('scroll', toggleVisibility);
        };
      }, []);

    return (
        <div>
            {isVisible && (
                <button onClick={scrollToTop} className="scrolltoTop-button">
                    <IoIosArrowUp className="scrolltoTop-icon"/>
                </button>
            )}
        </div>
    );
};

export default ScrolltoTopButton;